<template>
  <div>
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="twitter 1" clip-path="url(#clip0_99_39)">
        <path
          id="Vector"
          d="M29.9793 9.75697C30.0002 10.0494 30.0002 10.342 30.0002 10.6344C30.0002 19.5552 23.2104 29.834 10.8007 29.834C6.97746 29.834 3.42589 28.7266 0.438354 26.8047C0.98156 26.8673 1.5038 26.8882 2.06791 26.8882C5.22251 26.8882 8.1265 25.8228 10.4455 24.0052C7.47886 23.9425 4.99274 21.9996 4.13616 19.3254C4.55403 19.388 4.97184 19.4298 5.41061 19.4298C6.01645 19.4298 6.62235 19.3462 7.18639 19.2001C4.09443 18.5733 1.77537 15.8574 1.77537 12.5774V12.4938C2.67368 12.9952 3.71836 13.3086 4.82554 13.3503C3.00795 12.1386 1.81717 10.0703 1.81717 7.73045C1.81717 6.47697 2.15138 5.32792 2.73638 4.32511C6.05818 8.41989 11.0513 11.094 16.6503 11.3865C16.5458 10.8851 16.4831 10.3629 16.4831 9.84057C16.4831 6.1218 19.4916 3.09253 23.2312 3.09253C25.1741 3.09253 26.929 3.90731 28.1616 5.22349C29.6867 4.93102 31.1491 4.36691 32.4444 3.59393C31.943 5.16085 30.8775 6.47703 29.4778 7.31264C30.8358 7.16647 32.152 6.79034 33.3636 6.2681C32.4446 7.60511 31.2955 8.79589 29.9793 9.75697Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_99_39">
          <rect
            width="32.9254"
            height="32.9254"
            fill="white"
            transform="translate(0.438354)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
