<template>
  <div>
    <svg
      width="30"
      height="33"
      viewBox="0 0 30 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="whatsapp 1" clip-path="url(#clip0_99_57)">
        <path
          id="Vector"
          d="M25.2466 6.24452C22.5522 3.54361 18.9638 2.05811 15.1504 2.05811C7.27913 2.05811 0.874107 8.46313 0.874107 16.3344C0.874107 18.8488 1.53004 21.3053 2.77761 23.4725L0.751923 30.8679L8.32091 28.8808C10.4045 30.019 12.7517 30.6171 15.1439 30.6171H15.1504C23.0152 30.6171 29.5617 24.212 29.5617 16.3408C29.5617 12.5274 27.9411 8.94544 25.2466 6.24452ZM15.1504 28.212C13.0154 28.212 10.9254 27.6396 9.10546 26.5593L8.6746 26.302L4.18594 27.4788L5.38206 23.0995L5.09911 22.6494C3.90942 20.7587 3.28564 18.5787 3.28564 16.3344C3.28564 9.79429 8.6103 4.46964 15.1568 4.46964C18.3272 4.46964 21.3046 5.70434 23.5425 7.94867C25.7804 10.193 27.1566 13.1704 27.1501 16.3408C27.1501 22.8873 21.6904 28.212 15.1504 28.212ZM21.6583 19.3247C21.3046 19.1446 19.549 18.2829 19.221 18.1671C18.8931 18.0449 18.6551 17.9871 18.4172 18.3472C18.1792 18.7073 17.4976 19.5047 17.2854 19.7491C17.0796 19.987 16.8674 20.0192 16.5137 19.8391C14.4173 18.7909 13.0411 17.9678 11.6585 15.5948C11.2919 14.9646 12.025 15.0096 12.7067 13.6463C12.8224 13.4084 12.7646 13.2026 12.6745 13.0225C12.5845 12.8425 11.8707 11.0869 11.5749 10.3731C11.2855 9.67854 10.9897 9.775 10.771 9.76214C10.5652 9.74928 10.3273 9.74928 10.0894 9.74928C9.85143 9.74928 9.46558 9.83931 9.13762 10.193C8.80965 10.5531 7.89005 11.4148 7.89005 13.1704C7.89005 14.926 9.16977 16.6237 9.3434 16.8617C9.52346 17.0996 11.8578 20.7008 15.4397 22.2507C17.7034 23.2281 18.5908 23.3117 19.7226 23.1445C20.4107 23.0416 21.8319 22.2828 22.1277 21.4468C22.4235 20.6108 22.4235 19.897 22.3335 19.7491C22.2499 19.5883 22.012 19.4983 21.6583 19.3247Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_99_57">
          <rect
            width="28.8097"
            height="32.9254"
            fill="white"
            transform="translate(0.751923)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
