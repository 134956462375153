<template>
  <div>
    <svg
      width="30"
      height="33"
      viewBox="0 0 30 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="linkedin-in 1" clip-path="url(#clip0_99_43)">
        <path
          id="Vector"
          d="M6.81249 28.8096H0.839614V9.57523H6.81249V28.8096ZM3.82284 6.95148C1.91291 6.95148 0.363739 5.36952 0.363739 3.45959C0.363739 2.54218 0.728179 1.66234 1.37689 1.01363C2.02559 0.364928 2.90543 0.000488281 3.82284 0.000488281C4.74025 0.000488281 5.62008 0.364928 6.26879 1.01363C6.9175 1.66234 7.28194 2.54218 7.28194 3.45959C7.28194 5.36952 5.73213 6.95148 3.82284 6.95148ZM29.1671 28.8096H23.207V19.4464C23.207 17.215 23.162 14.3533 20.1016 14.3533C16.9962 14.3533 16.5203 16.7777 16.5203 19.2857V28.8096H10.5539V9.57523H16.2824V12.199H16.366C17.1634 10.6877 19.1113 9.09292 22.0174 9.09292C28.0623 9.09292 29.1735 13.0736 29.1735 18.2439V28.8096H29.1671Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_99_43">
          <rect
            width="28.8097"
            height="32.9254"
            fill="white"
            transform="translate(0.36377)"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>
