<template>
  <div>
    <svg
      width="21"
      height="33"
      viewBox="0 0 21 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="facebook-f 1">
        <path
          id="Vector"
          d="M17.8709 15.75L18.6488 10.6827H13.7853V7.3943C13.7853 6.00797 14.4647 4.65664 16.6429 4.65664H18.8539V0.342344C18.8539 0.342344 16.8475 0 14.9291 0C10.9239 0 8.30596 2.42703 8.30596 6.82063V10.6827H3.85388V15.75H8.30596V28H13.7853V15.75H17.8709Z"
          fill="black"
        />
      </g>
    </svg>
  </div>
</template>
